<html>
  <body>
    <div class="container">
      <div class="login-wrap">
        <div class="login-html">
          <input
            id="tab-1"
            type="radio"
            name="tab"
            class="sign-in"
            checked
          /><label for="tab-1" class="tab">Sign In</label>
          <input id="tab-2" type="radio" name="tab" class="sign-up" /><label
            for="tab-2"
            class="tab"
          ></label>
          <div class="login-form">
            <div class="sign-in-htm">
              <div class="group">
                <label for="user" class="label">Username</label>
                <input
                  id="user"
                  type="text"
                  class="input"
                  [(ngModel)]="loginModel.username"
                />
              </div>
              <div class="group">
                <label for="pass" class="label">Password</label>
                <input
                  id="pass"
                  type="password"
                  class="input"
                  data-type="password"
                  [(ngModel)]="loginModel.password"
                />
              </div>
              <div class="group">
                <input id="check" type="checkbox" class="check" checked />
                <label for="check"
                  ><span class="icon"></span> Keep me Signed in</label
                >
              </div>
              <div class="group">
                <input
                  type="submit"
                  class="button"
                  value="Sign In"
                  (click)="loginClick()"
                />
              </div>
              <div class="hr"></div>
              <div class="foot-lnk">
                <a href="#forgot">Forgot Password?</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-toast-message *ngIf="loginError" type="error" title="Authentication Error"
      content="Invalid credintials!">
    </app-toast-message>
  </body>
</html>
