import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginRequest } from '../model/request/login-request';
import { LoginResponse } from '../model/response/login-response';
import { ResourceUrl } from '../utils/resource-url';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient : HttpClient, private resourceUrl : ResourceUrl) { }

  public loginServiceCall(loginRequest : LoginRequest):Observable<any>{
    return this.httpClient.post(this.resourceUrl.userLogin, loginRequest);
  }
}
