import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from "../../services/login.service";
import { UserDetailsService } from "../../services/user-details.service";
import { LoginRequest } from "../../model/request/login-request";
import { LoginResponse } from 'src/app/model/response/login-response';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginModel = new LoginRequest();
  isLoader : boolean = false;
  loginError: boolean = false;
  
  loginResponse : LoginResponse;
  message : string;

  constructor(private router : Router,
     private loginService : LoginService,
     private userDetailsService: UserDetailsService,
     private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }
  
  public loginClick(){
    this.loginError = false
    this.isLoader = true;
    this.loginService.loginServiceCall(this.loginModel).subscribe((res: any) => {
      console.log(res)
      localStorage.setItem('token', res.data.accessToken);
      // this.userDetailsService.saveTokenObj(res.accessToken);
      this.success();
    },(err) => {
       this.snackBar.open(err.error.message, 'close')
    })
  }

  public disabledButton(){
    if(this.isLoader==true || this.loginModel.username==undefined || this.loginModel.username=='' || this.loginModel.username.length==0
     || this.loginModel.password==undefined || this.loginModel.password=='' || this.loginModel.password.length==0){
       return true;
     }
     return false;
  }

  public textOnchange(){
    this.message = '';
  }
  public success(){
    localStorage.setItem('loggedUsername',this.loginModel.username);
    this.router.navigate(['/dashboard'])
    .then(() => {
      window.location.reload();
    });
  }
}
