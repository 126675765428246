<div class="container">
  <div class="user-table data-table">
    <div class="top">
      <div class="filter search">
        <mat-icon>search</mat-icon>
        <input #serachIn type="text" id="search" placeholder="Search users..." 
          (keyup)="handleSearch($event, serachIn.value)">
        <mat-icon (click)="loadAllUsers()" title="load all">update</mat-icon>
      </div>
      <div class="pagintaion">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 20, 50, 100]" [showFirstLastButtons]="true"
          [length]="totalElements" [pageIndex]="pageIndex" (page)="handlePage($event)">
        </mat-paginator>
      </div>
    </div>
    <div class="table-data">
      <table>
        <thead>
          <tr>
            <th>Fullname</th>
            <th>Username</th>
            <th>Email</th>
            <th>Created At</th>
            <th>Emp. ID</th>
            <th>Department</th>
            <th>Designation</th>
            <th>Last Success login</th>
            <th>Last Failed Login</th>
            <th>Failed Attempts</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let u of userList">
            <td class="icon">
              <img src="assets/user.png" alt="">
              {{ u.fullName }}
            </td>
            <td>{{ u.credId }}</td>
            <td>{{ u.email }}</td>
            <td>{{ u.creationDate && u.creationDate.split('T')[0] }}</td>
            <td>{{ u.employeeId || '-' }}</td>
            <td>{{ u.department || 'Na' }}</td>
            <td>{{ u.designation || 'Na'}}</td>
            <td>{{ u.lastSuccessLogin ? u.lastSuccessLogin.split('T')[0] : '-' }}</td>
            <td>{{ u.lastFailedLogin ? u.lastFailedLogin.split('T')[0] : '-' }}</td>
            <td>{{ u.failedLoginCount }}</td>
            <td class="table-row-actions">
              <img (click)="onShowUpdateUser(u.workerId)" src="assets/edit.png" title="Edit">
              <img (click)="goToWorkerRoles(u.workerId)" src="assets/manage-role.png" title="Manage roles">
              <mat-slide-toggle 
                [checked]="u.credStatus == 'ACTIVE'" 
                (change)="updateWorkerCredentialsStatus(u.workerId, $event)">
              </mat-slide-toggle>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="no-data" *ngIf="userList.length == 0">
      No data found!
    </div>
    <div class="actions">
      <button class="icon" (click)="buildCreateUserForm()">
        <img src="assets/add.png" alt="">
        Create User
      </button>
    </div>

    <div class="moadl-container" *ngIf="showCreateUser">
      <div class="create-user-modal">
        <div class="title">
          <img src="assets/user.png" alt="">
          Create New User
        </div>
        <div class="modal-body">
          <form [formGroup]="createUserForm">
            <div class="form-group">
              <h3>User Info</h3>
              <div class="form-item">
                <label for="fullname">Full name</label>
                <input formControlName="fullName" type="text" id="fullname">
              </div>

              <div class="form-item">
                <label for="email">Email</label>
                <input formControlName="email" type="text" id="email">
              </div>

              <div class="form-item">
                <label for="password">Password</label>
                <input type="password" formControlName="password" id="password">
              </div>
            </div>
          </form>
        </div>
        <div class="modal-actions">
          <button (click)="createUser()">
            Create
          </button>
          <button (click)="toggleCreateUser(false)">Cancel</button>
        </div>
      </div>
    </div>

    <div class="moadl-container" *ngIf="showUpdateUser">
      <div class="update-user-modal">
        <div class="title">
          <img src="assets/user.png" alt="">
          Edit User
        </div>
        <div class="modal-body">
          <form [formGroup]="updateUserForm">
            <div class="form-group">
              <h3>User Info</h3>
              <div class="form-item">
                <label for="fullname">Full name</label>
                <input type="text" formControlName="fullName" id="fullname">
              </div>

              <div class="form-item">
                <label for="email">Email</label>
                <input formControlName="email" type="text" id="email">
              </div>

              <div class="form-item">
                <label for="password">Password</label>
                <input formControlName="password" type="password" id="password">
              </div>

            </div>
          </form>
        </div>
        <div class="modal-actions">
          <button (click)="updateUser()">
            Save
          </button>
          <button (click)="showUpdateUser=false">Cancel</button>
        </div>
      </div>
    </div>
  </div>