<div class="card-container">
  <img class="round" src="" alt="user" />
  <h3>TestUser</h3>
  <h6>India</h6>
  <p>ROLE:MANAGER</p>
  <div class="buttons">
    <button class="primary">ResetPassword</button>
    <button class="primary ghost">UpdateProfile</button>
  </div>
  <div class="skills">
    <h6>Skills</h6>
    <ul>
      <li>UI / UX</li>
      <li>Front End Development</li>
      <li>HTML</li>
      <li>CSS</li>
      <li>JavaScript</li>
      <li>React</li>
      <li>Node</li>
    </ul>
  </div>
</div>
