<div class="container">
  <div class="role-table data-table">
    <div class="top">
      <div class="filter">
        <select #groupListEl (change)="onChangeUserGroup(groupListEl.value)">
          <option [value]="-1">All</option>
          <option *ngFor="let g of groupList" [value]="g.groupId">
            {{ g.groupName }}
          </option>
        </select>
      </div>
      <div class="pagintaion">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true"
          [length]="totalElements" [pageIndex]="pageIndex" (page)="handlePage($event)">
        </mat-paginator>
      </div>
    </div>
    <div class="table-data">
      <table>
        <thead>
          <tr>
            <th>User Role</th>
            <th>Role Code</th>
            <th>Role Status</th>
            <th>User Group</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let r of roleList">
            <td class="icon">
              <img src="assets/role.png" alt="">
              {{ r.roleName }}
            </td>
            <td>{{ r.roleCode }}</td>
            <td>{{ r.roleStatus }}</td>
            <td>{{ r.groupName }}</td>
            <td class="table-row-actions" style="display: flex; align-items: center;">
              <img (click)="onShowUpdateRole(r.groupId, r.roleId)" src="assets/edit.png">
              <mat-slide-toggle 
                [checked]="r.roleStatus == 'ACTIVE'" 
                (change)="changeRoleStatus(r.roleId, $event)">
              </mat-slide-toggle>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="no-data" *ngIf="roleList.length == 0">
      No data found!
    </div>
    <div class="actions">
      <button class="icon" (click)="toggleCreateRole(true)">
        <img src="assets/add.png" alt="">
        Create Role
      </button>
    </div>

    <div class="moadl-container" *ngIf="showCreateRole">
      <div class="create-group-modal">
        <div class="title">
          <img src="assets/role.png" alt="">
          Create New UserRole
        </div>
        <div class="modal-body">
          <div>
            <label for="roleName">Role Name</label>
            <input #roleNameIn type="text" name="" id="roleName">

            <select (change)="loadPermissions(selectGroupEl.value)" #selectGroupEl name="" id="">
              <option *ngFor="let g of groupList" [value]="g.groupId">
                {{ g.groupName }}
              </option>
            </select>
          </div>

          <div class="perm-container">
            <ul class="perm-list">
              <li *ngFor="let p of permList">
                <input (change)="onSelectDeselectPerm(p.permId)" type="checkbox" [id]="p.permCode">
                <label [for]="p.permCode">{{ p.permName }} <span>[{{ p.permDesc }}]</span></label>
              </li>
            </ul>
            <div class="no-data" *ngIf="permList.length == 0">
              This userGroup has no permissions!
            </div>
          </div>
        </div>
        <div class="modal-actions">
          <button (click)="createRole(selectGroupEl.value, roleNameIn.value)">
            Create
          </button>
          <button (click)="toggleCreateRole(false)">Cancel</button>
        </div>
      </div>
    </div>

    <div class="moadl-container" *ngIf="showUpdateRole">
      <div class="update-role-modal">
        <div class="title">
          <img src="assets/role.png" alt="">
          Edit UserRole
        </div>
        <div class="modal-body">
          <div>
            <label for="roleName">Role Name</label>
            <input #roleNameIn type="text" id="roleName" [value]="selectedRole.role.roleName">
          </div>

          <div class="perm-container">
            <ul class="perm-list">
              <li *ngFor="let p of permList">
                <input (change)="onSelectDeselectPermUpdate(p.permId)" [checked]="handleCheckPerm(p.permId)" type="checkbox"
                  [id]="p.permCode">
                <label [for]="p.permCode">{{ p.permName }} <span>[{{ p.permDesc }}]</span></label>
              </li>
            </ul>
            <div class="no-data" *ngIf="permList.length == 0">
              This userGroup has no permissions!
            </div>
          </div>
        </div>
        <div class="modal-actions">
          <button (click)="updateRole(roleNameIn.value)">
            Save
          </button>
          <button (click)="toggleUpdateRole(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>