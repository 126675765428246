<div class="container">
    <div class="user-table data-table">
        <div class="top">
            <div class="worker-header">
                <span style="font-weight: bold;">{{workerDetails?.credId}}</span>
                <span>{{workerDetails?.email}}</span>
            </div>
        </div>
        <div class="table-data">
            <table>
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Role</th>
                        <th>Role code</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let ur of workerRoles">
                        <td class="icon">
                            <img src="assets/user.png" alt="">
                            {{ ur.workerUsername }}
                        </td>
                        <td class="icon">
                            <img src="assets/role.png" alt="">
                            {{ ur.roleName }}
                        </td>
                        <td>{{ ur.roleCode }}</td>
                        <td class="table-row-actions">
                            <img (click)="deleteWorkerRole(ur.workerId, ur.roleId)" src="assets/delete.png" alt="">
                            <mat-slide-toggle [checked]="ur.roleStatus == 'ACTIVE'"
                                (change)="changeWorkerRoleStatus(ur.roleId, ur.workerId, $event)">
                            </mat-slide-toggle>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="no-data" *ngIf="workerRoles.length == 0">
            User has no roles!
        </div>
    </div>
    <div class="add-worker-role">
        <label for="role"></label>
        <select #roleSelect id="role">
            <option *ngFor="let role of roleList" [value]="role.roleId">
                {{ role.roleName }}
            </option>
        </select>
        <button (click)="addRole(roleSelect.value)">
            Add role
        </button>
    </div>
</div>