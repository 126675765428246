import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switch-button',
  template: `
    <div class="switch-wrap">
        <input type="checkbox" id="something" />
        <div class="switch"></div>
    </div>
  `,
  styles: [
    `
        .wraper {
        display: grid;
        grid-template-columns: auto auto;
        gap: 2em;
        align-items: center;
        }
        
        .switch-wrap {
        cursor: pointer;
        background: #15273b;
        padding: 7px;
        width: 1.6rem;
        height: 0.8rem;
        border-radius: 33%;
        }

        .switch-wrap input {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
        }

        .switch {
        height: 100%;
        display: grid;
        grid-template-columns: 0fr 1fr 1fr;
        transition: 0.2s;
        }

        .switch::after {
        content: '';
        border-radius: 50%;
        background: #ccc;
        grid-column: 2;
        transition: background 0.2s;
        }

        .switch-wrap input:checked + .switch {
        grid-template-columns: 1fr 1fr 0fr;
        }

        .switch-wrap input:checked + .switch::after {
        background-color: #52cf71;
        }
    `
  ]
})
export class SwitchButton implements OnInit {
    ngOnInit(): void {
    }
}