<body>
  <router-outlet></router-outlet>
  <div class="container-fluid">
    <div *ngIf="sideMenu">
      <app-menu></app-menu>
    </div>
  </div>
</body>

<div class="loading" *ngIf="httpLoading">
  <app-snack-bar></app-snack-bar>
</div>