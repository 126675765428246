import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserAccessManagementRoutingModule } from './user-access-management-routing.module';
import { EditUserRolesComponent } from './manage-user-roles/edit-user-roles/edit-user-roles.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


@NgModule({
  declarations: [EditUserRolesComponent],
  imports: [
    CommonModule,
    UserAccessManagementRoutingModule,
    MatSlideToggleModule
  ]
})
export class UserAccessManagementModule { }
