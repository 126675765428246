import { Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";

@Component({
    selector: 'app-alert-message',
    template: `
        <div #alertBox [ngStyle]="{'border-color': type == 'error' ? 'red' : 'green'}" class="alert-box">
            <img #icon [src]="'assets/' + type + '.png'" class="icon"/>

            <div class="message">
                {{ message }}
            </div>
        </div>
    `,

    styles: [`
        .alert-box {
            position: fixed;
            z-index: 1000;
            top: 3rem;
            left: 2rem;
            padding: 0.3rem;
            display: flex;
            border-radius: 10px;
            border: 1px solid green;
            backdrop-filter: blur(10px);
            box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
            animation: trans 1s;
        }

        .message {
            font-size: 0.9rem;
            font-weight: 500;
            letter-spacing: 2px;
            max-width: 8rem;
        }

        .icon {
            margin-right: 0.4rem;
            width: 1rem;
            height: 1rem;
        }

        @keyframes trans {
            0% {left: -10rem}
            100% {left: 2rem}
        }
    `]
})
export class AlertMessageComponent {
    @Input() message = '';
    @Input() type = 'success';

    @ViewChild('alertBox')
     alertBox!: ElementRef;

    @ViewChild('icon')
     icon!: ElementRef;

}