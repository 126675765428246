import { HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LoadingService } from "../services/loading.service";
import { MatSnackBar } from '@angular/material/snack-bar';


const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(public router:Router, 
              private ls: LoadingService,
              private snackBar: MatSnackBar) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.ls.isLoading())
      this.ls.requestStart();
      
    if (!req.url.endsWith("userLogin")) {
      let token = localStorage.getItem('token');
       req = req.clone({
         setHeaders: { "Authorization": "bearer " + token},
         withCredentials: false
       });
     }
     return next.handle(req).pipe(
        catchError(error => this.handleAuthError(error)),
        finalize(()=> {
          if (this.ls.isLoading())
            this.ls.requestFinish();
        })
      ); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
  }
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem("loggedUsername");
        this.router.navigate(["/login"]);
        //return of(err.message); // or EMPTY may be appropriate here
    }

    this.snackBar.open(err.error.message, 'close')
    return throwError(err);
  }
}
