import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceUrl } from '../utils/resource-url';
import { WorkerRequest } from '../model/request/worker-req';
import { WorkerCredentialsStatusRequest } from '../model/request/worker-cred-status-req';

@Injectable({
  providedIn: 'root'
})
export class ManageUserService {

  constructor(private httpClient: HttpClient, private resourceUrl: ResourceUrl) { }

  public getUsersByGroup(grpId:number, page:number, size:number) {
    const url = `${this.resourceUrl.workers}/group/${grpId}?page=${page}&size=${size}`
    return this.httpClient.get(url)
  }

  public getAllUsers(page:number, size:number) {
    return this.httpClient.get(`${this.resourceUrl.workers}?page=${page}&size=${size}`)
  }

  public getUserById(credId: number) {
    return this.httpClient.get(`${this.resourceUrl.workers}/${credId}`)
  }

  public createWorker(req: WorkerRequest) {
    return this.httpClient.post(`${this.resourceUrl.workers}`, req);
  }

  public updateWorker(req: WorkerRequest) {
    return this.httpClient.put(`${this.resourceUrl.workers}`, req);
  }

  public updateWorkerCredentialsStatus(req: WorkerCredentialsStatusRequest) {
    return this.httpClient.put(`${this.resourceUrl.workers}/cred`, req)
  }

  public filterUsers(query: string, page: number, size: number) {
    return this.httpClient.get(`${this.resourceUrl.workers}/filter?query=${query}&page=${page}&size=${size}`)
  }

}
