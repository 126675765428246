import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './components/menu/menu.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTreeModule } from '@angular/material/tree';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ManageGroupComponent } from './components/user-management/manage-group/manage-group.component';
import { ManageRoleComponent } from './components/user-management/manage-role/manage-role.component';
import { ManageUserComponent } from './components/user-management/manage-user/manage-user.component';
import { ManageUserRolesComponent } from './components/user-management/manage-user-roles/manage-user-roles.component';
import { ManagePermissionComponent } from './components/user-management/manage-permission/manage-permission.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './config/AuthInterceptor';
import { ResourceUrl } from './utils/resource-url';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { MatSelectModule } from '@angular/material/select';
import { ProfileComponent } from './components/profile/profile.component';
import { SwitchButton } from './components/shared/switch-btn/switch-button.component'
import { ToastMessageComponent } from './components/shared/toast-msg/toast-message.component'
import { AlertMessageComponent } from './components/shared/alert-message/alert-message.component'
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserAccessManagementModule } from './components/user-management/user-access-management.module';
import {MatAutocompleteModule} from '@angular/material/autocomplete';


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    DashboardComponent,
    UserManagementComponent,
    ManageGroupComponent,
    ManageRoleComponent,
    ManageUserComponent,
    ManagePermissionComponent,
    LoginComponent,
    LogoutComponent,
    SnackBarComponent,
    ProfileComponent,
    SwitchButton,
    ToastMessageComponent,
    AlertMessageComponent,
    ManageUserRolesComponent
  ],
  imports: [
    MatSelectModule,
    MatSnackBarModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTreeModule,
    MatFormFieldModule,
    NoopAnimationsModule,
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatIconModule,
    MatTabsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSlideToggleModule,
    UserAccessManagementModule,
    MatAutocompleteModule
  ],
  providers: [
    ResourceUrl,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
