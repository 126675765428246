<div class="container">
  <div class="group-table data-table">
    <div class="pagintaion">
      <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true"
        [length]="totalElements" [pageIndex]="pageIndex" (page)="handlePage($event)">
      </mat-paginator>
    </div>
    <div class="table-data">
      <table>
        <thead>
          <tr>
            <th>User Group</th>
            <th>Group Code</th>
            <th>Group status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let g of groupList">
            <td class="icon">
              <img src="assets/group.png" alt="">
              {{ g.groupName }}
            </td>
            <td>{{ g.groupCode }}</td>
            <td>{{ g.groupStatus }}</td>
            <td class="table-row-actions">
              <img (click)="onShowUpdateGroup(g.groupId)" src="assets/edit.png" alt="">
              <mat-slide-toggle 
                [checked]="g.groupStatus == 'ACTIVE'" 
                (change)="changeGroupStatus(g.groupId, $event)">
              </mat-slide-toggle>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="no-data" *ngIf="groupList.length == 0">
      No data found!
    </div>
    <div class="actions">
      <button class="icon" (click)="toggleCreateModal(true)">
        <img src="assets/add.png" alt="">
        Create Group
      </button>
    </div>

    <div class="moadl-container" *ngIf="showCreateGroup">
      <div class="create-group-modal">
        <div class="title">
          <img src="assets/add-group.png" alt="">
          Create New UserGroup
        </div>
        <div class="modal-body">
          <div>
            <label for="grbName">Group Name</label>
            <input #groupNameIn type="text" name="" id="grbName">
          </div>

          <div class="perm-container"> 
            <div class="sublist" *ngFor="let item of permRes">
              <p *ngIf="item.permList.length > 0" class="name"
                  (click)="onExpandSubList(expIcon, item.servId)">
                {{ item.servName }}
                <span #expIcon class="material-symbols-outlined">
                  expand_more
                </span>
              </p>

              <ul [id]="item.servId" *ngIf="item.permList.length > 0">
                <li *ngFor="let p of item.permList">
                  <input (change)="onSelectDeselectPerm(p.permId)" type="checkbox" [id]="p.permCode">
                  <label [for]="p.permCode">{{ p.permName }} <span>[{{ p.permDesc }}]</span></label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-actions">
          <button (click)="createGroup(groupNameIn.value)">
            Create
          </button>
          <button (click)="toggleCreateModal(false)">Cancel</button>
        </div>
      </div>
    </div>

    <div class="moadl-container" *ngIf="showUpdateGroup">
      <div class="update-group-modal">
        <div class="title">
          <img src="assets/add-group.png" alt="">
          Edit UserGroup
        </div>
        <div class="modal-body">
          <div>
            <label for="grbName">Group Name</label>
            <input #groupNameIn type="text" id="grbName" [value]="selectedGroup.userGroup.groupName">
          </div>

          <div class="perm-container">
            <div class="sublist" *ngFor="let item of permRes">
              <p *ngIf="item.permList.length > 0" class="name" 
                  (click)="onExpandSubList(expIcon, item.servId)">
                {{ item.servName }}
                <span #expIcon class="material-symbols-outlined">
                  expand_more
                </span>
              </p>

              <ul [id]="item.servId" *ngIf="item.permList.length > 0">
                <li *ngFor="let p of item.permList">
                  <input [checked]="handleCheckPerm(p.permId)" (change)="onSelectDeselectPermUpdate(p.permId)"
                    type="checkbox" [id]="p.permCode">
                  <label [for]="p.permCode">{{ p.permName }} <span>[{{ p.permDesc }}]</span></label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-actions">
          <button (click)="updateGroup(groupNameIn.value)">
            Save
          </button>
          <button (click)="toggleUpdateModal(false)">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>