<div class="container">
  <div class="user-table data-table">
    <div class="top">
      <div class="filter">
        <!-- <select #groupListEl (change)="onChangeUserGroup(groupListEl.value)">
          <option [value]="-1">All</option>
          <option *ngFor="let g of groupList" [value]="g.groupId">
            {{ g.groupName }}
          </option>
        </select> -->
      </div>
      <div class="pagintaion">
        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]" [showFirstLastButtons]="true"
          [length]="totalElements" [pageIndex]="pageIndex" (page)="handlePage($event)">
        </mat-paginator>
      </div>
    </div>
    <div class="table-data">
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Role</th>
            <th>Role code</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ur of userRoleList">
            <td class="icon">
              <img src="assets/user.png" alt="">
              {{ ur.workerUsername }}
            </td>
            <td class="icon">
              <img src="assets/role.png" alt="">
              {{ ur.roleName }}
            </td>
            <td>{{ ur.roleCode }}</td>
            <td class="table-row-actions">
              <img [routerLink]="[ur.workerId]" src="assets/edit.png" alt="">
              <!-- <mat-slide-toggle 
                [checked]="ur.roleStatus == 'ACTIVE'" 
                (change)="changeWorkerRoleStatus(ur.roleId, ur.workerId, $event)">
              </mat-slide-toggle> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="no-data" *ngIf="userRoleList.length == 0">
      No data found!
    </div>
  </div>
</div>