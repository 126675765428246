<html>
  <body>
    <div class="left-side">
      <nav class="main-menu">
        <ul>
          <li
            class="has-subnav"
            *ngFor="let sideMenu of sideMenus"
            [class]="
              sideMenu.selected
                ? 'left-side-menu-item-select menu-item'
                : 'left-side-menu-item menu-item '
            "
            (click)="redirectTo(sideMenu)"
          >
            <a>
              <i class="fa {{ sideMenu.icon }} fa-2x"></i>
              <span class="nav-text"> {{ sideMenu.name }} </span>
            </a>
          </li>
        </ul>
        <ul class="profile">
          <li (click)="profile()">
            <a>
              <i class="fa fa-cog fa-2x"></i>
              <span class="nav-text"> Account </span>
            </a>
          </li>
        </ul>
        <ul class="logout">
          <li (click)="logout()">
            <a>
              <i class="fa fa-power-off fa-2x"></i>
              <span class="nav-text"> Logout </span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </body>
</html>
