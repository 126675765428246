<div class="menu">
  <div class="card-container">
    <div routerLink="groups" class="card">
      <div class="front">
        <span class="fa fa-users"></span>
      </div>
      <div class="back">Groups</div>
    </div>
  </div>
  <div class="card-container">
    <div routerLink="roles" class="card">
      <div class="front">
        <span class="fa fa-user"></span>
      </div>
      <div class="back">Roles</div>
    </div>
  </div>
  <div class="card-container">
    <div routerLink="users" class="card">
      <div class="front">
        <span class="fa fa-address-card-o"></span>
      </div>
      <div class="back">Users</div>
    </div>
  </div>
  <div class="card-container">
    <div routerLink="permissions" class="card">
      <div class="front">
        <!-- <span class="fa fa-address-card-o"></span> -->
        <img src="assets/permissions.png" alt="">
      </div>
      <div class="back">Permissions</div>
    </div>
  </div>
  <div class="card-container">
    <div routerLink="user-roles" class="card">
      <div class="front">
        <!-- <span class="fa fa-address-card-o"></span> -->
        <img src="assets/user-role.png" alt="">
      </div>
      <div class="back">User Roles</div>
    </div>
  </div>
</div>
<div class="app-manage">
  <div class="sub-menu">
    <router-outlet></router-outlet>
  </div>
</div>