import { Component, OnInit } from '@angular/core';
import { UserRoleResponse } from 'src/app/model/response/user-role-response';
import { UserRoleMappingService } from '../../../services/user-role-mapping.service';
import { UserRoleMappingRequest } from 'src/app/model/request/user-role-mapping-req';

@Component({
  selector: 'app-manage-user-roles',
  templateUrl: './manage-user-roles.component.html',
  styleUrls: ['./manage-user-roles.component.css']
})
export class ManageUserRolesComponent implements OnInit {

  userRoleList: UserRoleResponse[] = [];

  // Pagination
  pageIndex: number = 0;
  pageSize: number = 10;
  totalElements: 0;

  constructor(private workerRoleService: UserRoleMappingService) {}

  ngOnInit(): void {
    this.loadWorkersRoles();
  }

  loadWorkersRoles() {
    this.workerRoleService.getAllWorkersRoles(this.pageIndex, this.pageSize)
      .subscribe((res: any) => {
        this.userRoleList = res.data.content;
        this.updatePagination(res.data)
      })
  }

  handlePage(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize
    this.loadWorkersRoles()
  }

  changeWorkerRoleStatus(roleId:number, workerId: number, event: any) {
    const req = new UserRoleMappingRequest();
    req.roleId = roleId;
    req.workerId = workerId;
    req.active = event.checked;
    this.workerRoleService.updateWorkerRoleMapping(req)
      .subscribe((res: any) => console.log(res.message))
  }

  updatePagination(res) {
    this.pageIndex = res.number;
    this.pageSize = res.size;
    this.totalElements = res.totalElements; 
  }
  
}
