<div class="container">
    <div class="permission-table data-table">
        <div class="top">
            <div class="filter">
                <select #servListEl (change)="onChangeService(servListEl.value)">
                    <option [value]="-1">All</option>
                    <option *ngFor="let s of servList" [value]="s.servId">
                        {{ s.servName }}
                    </option>
                </select>
                <button (click)="showManageModule=true">Manage Modules</button>
            </div>
            <div class="pagintaion">
                <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100]"
                    [showFirstLastButtons]="true" [length]="totalElements" [pageIndex]="pageIndex"
                    (page)="handlePage($event)">
                </mat-paginator>
            </div>
        </div>
        <div class="table-data">
            <table>
                <thead>
                    <tr>
                        <th>Permission</th>
                        <th>Code</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let p of permList">
                        <td class="icon">
                            <img src="assets/access.png" alt="">
                            {{ p.permName }}
                        </td>
                        <td>{{ p.permCode }}</td>
                        <td>{{ p.permType }}</td>
                        <td>{{ p.permDesc }}</td>
                        <td class="table-row-actions">
                            <img (click)="onShowUpdatePerm(p.permId)" src="assets/edit.png" alt="">
                            <img (click)="onDeletePerm(p.permId)" src="assets/delete.png" alt="">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="no-data" *ngIf="permList.length == 0">
            No data found!
        </div>
        <div class="actions">
            <button (click)="onShowCreatePerm()">
                <img src="assets/add.png" alt="">
                Create Permission
            </button>
        </div>
    </div>
    <div class="moadl-container" *ngIf="showCreatePerm">
        <div class="register-api-modal">
            <div class="title">
                <img src="assets/access.png" alt="">
                Create Module Permission
            </div>
            <div class="modal-body">
                <form [formGroup]="permForm" class="create-api">
                    <h3>Module Permission</h3>

                    <div class="form-group">
                        <div class="form-item">
                            <label for="service">Module</label>
                            <select formControlName="servId" id="service">
                                <option *ngFor="let s of servList" [value]="s.servId">
                                    {{ s.servName }}
                                </option>
                            </select>
                        </div>
                        <div class="form-item">
                            <label for="apiName">Permission Name</label>
                            <input formControlName="permName" type="text" id="apiName" placeholder="Permission name">
                        </div>
                        <div class="form-item">
                            <label for="permType">Permission For</label>
                            <select formControlName="permType" id="permType">
                                <option value="UI">UI</option>
                                <option value="API">API</option>
                            </select>
                        </div>

                        <div class="form-item">
                            <label for="permDesc">Permission Desc</label>
                            <input formControlName="permDesc" type="text" placeholder="Permission description">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-actions">
                <button [disabled]="!permForm.valid" (click)="createPermission()">
                    Create
                </button>
                <button (click)="showCreatePerm=false">Cancel</button>
            </div>
        </div>
    </div>

    <div class="moadl-container" *ngIf="showUpdatePerm">
        <div class="edit-perm-modal">
            <div class="title">
                <img src="assets/access.png" alt="">
                Edit Module Permission
            </div>
            <div class="modal-body">
                <form [formGroup]="updatePermForm" class="create-api">
                    <h3>Module Permission</h3>

                    <div class="form-group">
                        <div class="form-item">
                            <label for="service">Module</label>
                            <select formControlName="servId" id="service">
                                <option *ngFor="let s of servList" [value]="s.servId">
                                    {{ s.servName }}
                                </option>
                            </select>
                        </div>

                        <div class="form-item">
                            <label for="permCode">Permission Code</label>
                            <input readonly formControlName="permCode" type="text">
                        </div>

                        <div class="form-item">
                            <label for="apiName">Permission Name</label>
                            <input formControlName="permName" type="text" id="apiName" placeholder="Permission name">
                        </div>
                        <div class="form-item">
                            <label for="permType">Permission For</label>
                            <select formControlName="permType" id="permType">
                                <option value="UI">UI</option>
                                <option value="API">API</option>
                            </select>
                        </div>

                        <div class="form-item">
                            <label for="permDesc">Permission Desc</label>
                            <input formControlName="permDesc" type="text" placeholder="Permission description">
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-actions">
                <button [disabled]="!updatePermForm.valid" (click)="updatePermission()">
                    Save
                </button>
                <button (click)="showUpdatePerm=false">Cancel</button>
            </div>
        </div>
    </div>

    <div class="moadl-container" *ngIf="showManageModule">
        <div class="manage-module-modal">
            <div class="title">
                <div>
                    <img src="assets/access.png" alt="">
                    Manage Modules
                </div>
                <!-- <div (click)="showManageModule=false">
                    <span class="material-symbols-outlined">
                        close
                    </span>
                </div> -->
            </div>
            <div class="modal-body">
                <div class="module-list">
                    <h3>Modules</h3>
                    <ul>
                        <li *ngFor="let ss of servList">
                            {{ ss.servName }}
                        </li>
                    </ul>
                </div>
                <div>
                    <label for="modName">Module Name</label>
                    <input #nameIn type="text" id="modName">
                    <button (click)="createNewModule(nameIn.value)">
                        Create New Module
                    </button>
                </div>
            </div>
            <div class="modal-actions">
                <button (click)="showManageModule=false">Return</button>
            </div>
        </div>
    </div>
</div>