import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from "./services/loading.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  title = 'UserManagement';
 
  sideMenu : boolean;
  loggedUsername = '';
  isLoginPage = '';
  httpLoading = false;

  constructor(private router : Router,
              private loadingService: LoadingService,
              private cdr: ChangeDetectorRef) {
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          console.log('this.router.url', this.router.url);
          let currentURL = this.router.url;
          if(currentURL !=='/login'){
            this.loggedUsername = localStorage.getItem('loggedUsername');
            if(this.loggedUsername !=null && this.loggedUsername!=undefined && this.loggedUsername!=''){
              this.sideMenu = true;
            }else{
              this.sideMenu = false;
              this.redirectTo('login');
            }
          }else{
            this.sideMenu = false;
          }
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.loadingService.loading.subscribe(next => {
      this.httpLoading = next
      this.cdr.detectChanges()
    })
  }
  
  public logout(){
    localStorage.removeItem("loggedUsername");
    localStorage.removeItem('permissions');
    localStorage.removeItem('token');
    localStorage.removeItem('companyCode');
    localStorage.removeItem('groupCode');
    localStorage.removeItem('menu-permissions');
    localStorage.clear();
    this.redirectTo('login');
  }

  public redirectTo(pageName){
    this.router.navigate(['/' + pageName])
    .then(() => {
      window.location.reload();
    });
  }
}
