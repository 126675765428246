import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceUrl } from '../utils/resource-url';
import { Observable } from 'rxjs';
import { GroupResponse } from '../model/response/group-response';
import { GroupStatusRequest } from '../model/request/group-status-req';
import { UserGroupRequest } from '../model/request/user-group-req';
import { BaseResponse } from '../model/response/base-response';

@Injectable({
  providedIn: 'root'
})
export class ManageGroupService {

  constructor(private httpClient : HttpClient, private resourceUrl : ResourceUrl) { }

  public getAllGroups(page: number, size: number) {
    return this.httpClient.get(`${this.resourceUrl.userGroups}?page=${page}&size=${size}`)
  }

  public createUserGroup(req: UserGroupRequest) {
    return this.httpClient.post(`${this.resourceUrl.userGroups}`, req);
  }

  public deleteUserGroup(grbId: number) {
    return this.httpClient.delete(`${this.resourceUrl.userGroups}/${grbId}`);
  }

  public updateUserGroup(req: UserGroupRequest) {
    return this.httpClient.put(`${this.resourceUrl.userGroups}`, req);
  }

  public getUserGroupDetails(grbId: number) {
    return this.httpClient.get(`${this.resourceUrl.userGroups}/${grbId}`)
  }

  public changeGroupStatus(req: GroupStatusRequest) {
    return this.httpClient.put(`${this.resourceUrl.userGroups}/status`, req)
  }

}
