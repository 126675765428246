import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ManageGroupComponent } from './manage-group/manage-group.component';
import { ManageRoleComponent } from './manage-role/manage-role.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { ManagePermissionComponent } from './manage-permission/manage-permission.component';
import { ManageUserRolesComponent } from './manage-user-roles/manage-user-roles.component';
import { EditUserRolesComponent } from './manage-user-roles/edit-user-roles/edit-user-roles.component';

const routes: Routes = [
 {
  path: 'groups',
  component: ManageGroupComponent
 },
 {
  path: 'roles',
  component: ManageRoleComponent
 },
 {
  path: 'users',
  component: ManageUserComponent
 },
 {
  path: 'permissions',
  component: ManagePermissionComponent
 },
 {
  path: 'user-roles',
  component: ManageUserRolesComponent,
 },
 {
  path: 'user-roles/:workerId',
  component: EditUserRolesComponent,
 }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserAccessManagementRoutingModule { }
