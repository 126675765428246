import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
    constructor() {}
    tokenObj: any;
    private userPermissions: string[] = []

    saveTokenObj(token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        this.tokenObj = JSON.parse(jsonPayload);
        this.populateAuthorities
    }

    private populateAuthorities() {
        this.userPermissions = this.tokenObj.authorities.split(',')
    }

    public getUserPermissions() {
        return this.userPermissions
    }
}

