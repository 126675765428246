import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiPermissionRequest } from 'src/app/model/request/api-perm-req';
import { ModuleRequest } from 'src/app/model/request/module-req';
import { PermissionService } from '../../../services/permission.service';

@Component({
  selector: 'app-manage-permission',
  templateUrl: './manage-permission.component.html',
  styleUrls: ['./manage-permission.component.css']
})
export class ManagePermissionComponent implements OnInit {

  constructor(private permissionService: PermissionService,
              private formBuilder: FormBuilder) {}

  permForm: FormGroup;
  updatePermForm: FormGroup;

  permList = [];
  servList = [];
  showCreatePerm = false;
  showUpdatePerm = false;
  showManageModule = false;
  selectedServiceId = -1;
  selectedPerm;

  // Pagination
  pageIndex: number = 0;
  pageSize: number = 10;
  totalElements: 0;

  ngOnInit(): void {
    this.listServices()
    this.listAllPermissions()
  }
  
  buildPermForm() {
    this.permForm = this.formBuilder.group({
      servId: ['', Validators.required],
      permName: ['', Validators.required],
      permType: ['UI', Validators.required],
      permDesc: ['', Validators.required],
    });
  }

  buildUpdatePermForm() {
    this.updatePermForm = this.formBuilder.group({
      servId: [this.selectedPerm.servId, Validators.required],
      permCode: [this.selectedPerm.permCode],
      permName: [this.selectedPerm.permName, Validators.required],
      permType: [this.selectedPerm.permType, Validators.required],
      permDesc: [this.selectedPerm.permDesc, Validators.required],
    });
  }

  listServices() {
    this.permissionService
        .getAllServices()
        .subscribe((res:any) => {
          this.servList = res
        })
  }

  listAllPermissions() {
    this.permissionService
    .getAllPermissions(this.pageIndex, this.pageSize)
    .subscribe((res:any) => {
        this.permList = res.content
        this.updatePagination(res)
    })
  }

  listServicePermissions(servId: number) {
    this.permissionService
        .getServicePermissions(servId, this.pageIndex, this.pageSize)
        .subscribe((res:any) => {
            this.permList = res.content
            this.updatePagination(res)
        })
  }

  listPermissions(servId: number) {
    if (servId == -1)
      this.listAllPermissions()
    else
      this.listServicePermissions(servId);
    
      this.selectedServiceId = servId
  }

  createPermission() {
    const req = new ApiPermissionRequest();
    const form = this.permForm.value
    req.serviceId = form.servId;
    req.permName = form.permName;
    req.permType = form.permType;
    req.permDesc = form.permDesc;
    
    if (this.permForm.valid) {
      this.permissionService
      .createServicePermission(req)
      .subscribe(res => {
        this.listPermissions(this.selectedServiceId)
        this.showCreatePerm = false
      })
    }
  }

  updatePermission() {
    const req = new ApiPermissionRequest();
    const form = this.updatePermForm.value
    req.serviceId = form.servId;
    req.permName = form.permName;
    req.permType = form.permType;
    req.permDesc = form.permDesc;
    req.permId = this.selectedPerm.permId

    if (this.updatePermForm.valid) {

      this.permissionService
      .updateServicePermission(req)
      .subscribe(res => {
        this.listPermissions(this.selectedServiceId)
        this.showUpdatePerm = false
      })
    }
  }

  createNewModule(name: string) {
    if (name.length > 0) {
      const req = new ModuleRequest()
      req.name = name
      this.permissionService
        .createNewModule(req)
        .subscribe(res => {
          this.listServices()
      })
    }
  }

  onShowCreatePerm() {
    this.buildPermForm()
    this.showCreatePerm = true
  }

  onShowUpdatePerm(permId: number) {
    this.permissionService
        .getPermissionDetails(permId)
        .subscribe(res => {
          this.selectedPerm = res
          this.buildUpdatePermForm()
          this.showUpdatePerm = true
        })
  }

  onDeletePerm(permId: number) {
    if (confirm("Delete this permission from all the groups and roles?")){
      this.permissionService
        .deleteServicePermission(permId)
        .subscribe(res => {
          this.listPermissions(this.selectedServiceId)
        })
    }
  }

  
  handlePage(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize
    this.listPermissions(this.selectedServiceId);
  }

  onChangeService(servId: number) {
    this.pageIndex = 0
    this.listPermissions(servId)
  }

  updatePagination(res) {
    this.pageIndex = res.number;
    this.pageSize = res.size;
    this.totalElements = res.totalElements; 
  }
}
