import { Component, OnInit } from '@angular/core';
import { ManageUserService } from '../../../services/manage-user.service'
import { WorkerRequest } from 'src/app/model/request/worker-req';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WorkerCredentialsStatusRequest } from 'src/app/model/request/worker-cred-status-req';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {

  constructor(private userService: ManageUserService,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private router: Router) {}

  showCreateUser = false;
  showUpdateUser = false;
  searchMode = false;
  searchQuery = '';
  selectedUser;
  userList = [];

  createUserForm: FormGroup;
  updateUserForm: FormGroup;

  // Pagination
  pageIndex: number = 0;
  pageSize: number = 10;
  totalElements: 0;

  ngOnInit(): void {
    this.loadAllUsers()
  }

  loadUsers() {
    if (this.searchMode)
      this.searchUsers();
    else
      this.loadAllUsers();
  }


  loadAllUsers() {
    this.userService
        .getAllUsers(this.pageIndex, this.pageSize)
        .subscribe((res: any) => {
            this.userList = res.content
            this.updatePagination(res)
        })
  }

  handlePage(event: any) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize
    this.loadUsers()
  }

  updatePagination(res) {
    this.pageIndex = res.number;
    this.pageSize = res.size;
    this.totalElements = res.totalElements; 
  }
  
  createUser() {
    if (!this.createUserForm.valid)
      return

    const form = this.createUserForm.value
    const req = new WorkerRequest();
    req.fullName = form.fullName;
    req.email = form.email;
    req.password = form.password;
    req.changePassword = false;

    this.userService
      .createWorker(req)
      .subscribe((res: any) => {
        this.showCreateUser = false
        this.loadAllUsers()
        this.snackBar.open(res.message, 'close')
      })
  }

  updateUser() {
    if (!this.updateUserForm.valid)
      return

    const form = this.updateUserForm.value
    const req = new WorkerRequest();
    req.workerId = this.selectedUser.workerId
    req.fullName = form.fullName;
    req.email = form.email;
    req.password = form.password;
    req.changePassword = form.password ? true : false;
    
    console.log(req)
    this.userService
      .updateWorker(req)
      .subscribe((res: any) => {
          this.loadAllUsers()
          this.showUpdateUser = false;
          this.snackBar.open(res.message, 'close')
      })
  }

  onShowUpdateUser(wrkId: number) {
    this.userService
        .getUserById(wrkId)
        .subscribe((res: any) => {
            this.selectedUser = res
            this.buildUpdateUserForm()
        })
  }

  toggleCreateUser(show: boolean) {
    this.showCreateUser = show
  }

  buildCreateUserForm() {
    this.createUserForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    })
    this.toggleCreateUser(true);
  }

  buildUpdateUserForm() {
    this.updateUserForm = this.formBuilder.group({
      fullName: [this.selectedUser.fullName, Validators.required],
      email: [this.selectedUser.email, [Validators.required, Validators.email]],
      password: [this.selectedUser.password]
    })
    this.showUpdateUser = true;
  }

  updateWorkerCredentialsStatus(workerId: number, event: any) {
    const req = new WorkerCredentialsStatusRequest();
    req.workerId = workerId
    req.active = event.checked;
    this.userService.updateWorkerCredentialsStatus(req)
      .subscribe(res => console.log(res))
  }
  
  handleSearch(event: any, query: string) {
    if (event.key == 'Enter' && query) {
      this.searchMode = true;
      this.searchQuery = query;
      this.pageIndex = 0;
      this.loadUsers();
    }
  }

  searchUsers() {
    this.userService.filterUsers(this.searchQuery, this.pageIndex, this.pageSize)
      .subscribe((res: any) => {
        this.userList = res.data.content
        this.updatePagination(res.data)
      })
  }

  goToWorkerRoles(workerId: number) {
    this.router.navigate(['access-management', 'user-roles', workerId])
  }

}
