import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceUrl } from '../utils/resource-url';
import { UserRoleMappingRequest } from '../model/request/user-role-mapping-req';

@Injectable({
    providedIn: 'root'
})
export class UserRoleMappingService {

    baseUrl = this.resource.workerRoles;

    constructor(private http: HttpClient,
        private resource: ResourceUrl) {}

    public getAllWorkersRoles(page: number, size: number) {
        return this.http.get(`${this.baseUrl}?page=${page}&size=${size}`);
    }

    public getWorkerRoles(workerId: number) {
        return this.http.get(`${this.baseUrl}/${workerId}`);
    }

    public addWorkerRoleMapping(req: UserRoleMappingRequest) {
        return this.http.post(this.baseUrl, req);
    }

    public updateWorkerRoleMapping(req: UserRoleMappingRequest) {
        return this.http.put(this.baseUrl, req);
    }

    public deleteWorkerRoleMapping(workerId: number, roleId: number) {
        return this.http.delete(`${this.baseUrl}?workerId=${workerId}&roleId=${roleId}`);
    }

}