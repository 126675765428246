import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

export interface Section {
  name: string;
  link: String;
  icon: String;
  id: number;
  selected: boolean;
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  constructor(private router: Router, private appCompObj: AppComponent) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        console.log('this.router.url----', this.router.url);
        let url = this.router.url;
        console.log(this.sideMenus);
        for (let sideMenuItem of this.sideMenus) {
          let compareURL = '/' + sideMenuItem.link;
          console.log(url);
          console.log(compareURL);
          if (url === compareURL) {
            console.log(compareURL);
            sideMenuItem.selected = true;
          } else {
            sideMenuItem.selected = false;
          }
        }
      }
    });
  }
  logout() {
    this.appCompObj.logout();
  }
  profile() {
    this.router.navigate(['/profile']);
  }
  panelOpenState = false;
  ngOnInit(): void {}
  defaultSelected = false;
  sideMenus: Section[] = [
    {
      name: 'Dashboard',
      link: 'dashboard',
      icon: 'fa-home',
      id: 1,
      selected: true,
    },
    {
      name: 'User Management',
      link: 'access-management',
      icon: 'fa-users',
      id: 2,
      selected: this.defaultSelected,
    },
  ];
  public redirectTo(sideMenu) {
    for (let sideMenuItem of this.sideMenus) {
      sideMenuItem.selected = false;
    }
    sideMenu.selected = true;
    this.router.navigate(['/' + sideMenu.link]);
  }
}
