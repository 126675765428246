import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    loading = new BehaviorSubject<boolean>(false);

    public requestStart() {
        this.loading.next(true);
    }

    public requestFinish() {
        this.loading.next(false);
    }

    public isLoading() {
        return this.loading.getValue()
    }
}