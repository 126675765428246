import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceUrl } from '../utils/resource-url';
import { Observable } from 'rxjs';
import { PermissionList } from '../model/response/permission list';
import { BaseResponse } from '../model/response/base-response';
import { ApiPermissionRequest } from '../model/request/api-perm-req';
import { ModuleRequest } from '../model/request/module-req';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private httpClient : HttpClient, private resourceUrl : ResourceUrl) { }

  public getAllServices() {
    return this.httpClient.get(`${this.resourceUrl.getService}`)
  }

  public createNewModule(req: ModuleRequest) {
    return this.httpClient.post(`${this.resourceUrl.getService}`, req)
  }

  public getAllPermissions(page: number, size: number) {
    const url = `${this.resourceUrl.permissions}?page=${page}&size=${size}`
    return this.httpClient.get(url)
  }

  public getPermissionDetails(permId: number) {
    return this.httpClient.get(`${this.resourceUrl.permissions}/${permId}`)
  }

  public getServicePermissions(servId: number, page: number, size: number) {
    const url = `${this.resourceUrl.permissions}/service/${servId}?page=${page}&size=${size}`
    return this.httpClient.get(url)
  }

  public getGroupPermissions(grpId: number) {
    return this.httpClient.get(`${this.resourceUrl.permissions}/group/${grpId}`)
  }

  public getAllPermissionsGrouped() {
    return this.httpClient.get(`${this.resourceUrl.permissions}/grouped`)
  }
  
  public createServicePermission(req: ApiPermissionRequest) {
    return this.httpClient.post(`${this.resourceUrl.permissions}`, req)
  }

  public updateServicePermission(req: ApiPermissionRequest) {
    return this.httpClient.put(`${this.resourceUrl.permissions}`, req)
  }

  public deleteServicePermission(permId: number) {
    return this.httpClient.delete(`${this.resourceUrl.permissions}/${permId}`)
  }
}
